/* @import 'bootstrap/scss/bootstrap'; */
body {
  margin: 0;
  font-family: Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Montserrat, sans-serif !important;
}

.home-main-bg {}

.header-main-bg {
  position: relative;
  z-index: 1000;
}

.fixed-top {
  top: 0;
  width: 100%;
  z-index: 1030;
  background-color: white; /* Ensure the background color is the same */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better visibility */
}

.header-btns {
  color: #304cd1 !important;
  font-weight: 700 !important;
  cursor: pointer;
}

.home-main-text {
  text-align: left;
}

.home-main-text h1 {
  color: #304cd1;
  font-size: 50px;
  font-weight: 700 !important;
}

.home-main-text p {
  font-size: 20px;
  font-weight: 500;
}

.home-main-btn-div {
  text-align: left;
}

.home-main-btn-div button {
  background-color: #304cd1;
  color: white;
  padding: 10px 80px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
  border: none;
  margin-top: 25px;
}

.home-main-btn-div button:hover {
  background-color: white;
  color: #304cd1;
  animation-name: shine;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes shine {

  0%,
  100% {
    box-shadow: 0px 0px 32px -12px #304cd1;
  }

  50% {
    box-shadow: 0px 0px 32px 3px #304cd1;
  }
}

.home-main-gif {
  object-fit: contain;
  width: 100%;
}

.about-text-div h2 {
  margin-top: 40px;
  margin-bottom: 26px;
  font-size: 40px;
  font-weight: 700;
  color: #304cd1;
}

.about-main-git-div img {
  object-fit: contain;
  width: 100%;
}

.about-body-text {
  text-align: left;
}

.about-body-text p {
  font-size: 20px;
  font-weight: 400;
}

.sec3-card-main {
  border: none !important;
  margin: 19px 15px;
  /* border: 1px solid black; */
  border-radius: 10px;
  background-color: #495fcc !important;
  padding: 20px;
  color: #fff !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
  width: calc(33% - 36px);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px 2px #d7d7d7;
  text-align: left;
}

.sec3-card-main-h h5 {
  font-weight: 700;
  color: white;
}

.sec3-card-main:hover {
  background-color: #04d2b8 !important;
  color: #fff !important;
  transform: scale(1.05);
}

.home-sec4-img-div img {
  object-fit: contain;
  width: 100%;
}

.home-sec4-txt-div {
  text-align: left;
}

.sec5-bg {
  background-color: #943ecc;
  padding-top: 1px !important;
  padding-bottom: 10px;
  margin-top: 10px;
}

.home-sec5-lst {
  display: flex;
  align-items: center;
  gap: 1px 3px;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  border-radius: 40px;
  padding: 4px 8px;
}

.home-sec5-lst p {
  padding: 4px 7px;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.about-text-div-h2 {
  color: white !important;
}

.home-sec5-lst-svg {
  width: 25px;
  height: auto;
  color: white;
}

.about-sec-bg {
  background-color: #943ecc;
}

/* .about-text{
    color: white !important;
  } */


/* .home-sec5-lst:hover {
  box-shadow: inset 350px 0 0 0 #000000;
  color: white !important;
} */

.home-sec5-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px 50px;
}

.home-sec6-txt-div p {
  text-align: left;
  font-weight: 500;
}

.home-sec6-img-div img {
  object-fit: contain;
  width: 100%;
}

.sec7-card-main {
  background-color: #702F8B !important;
  border: none !important;
  border-radius: 10px;
  box-shadow: 0 10px 10px 2px #d7d7d7;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  padding: 18px;
  position: relative;
  width: calc(33% - 36px);
  text-align: left;
  display: flex;
  color: white !important;
}

.sec7-card-main-h5 {
  font-size: 20px;
  color: white;
  font-weight: 700 !important;
}

.sec7-card-main-b p {
  font-size: 12px;
  color: white;
  margin: 0;
}

.sec7-card-main-b-svg {
  margin: 0 5px;
  width: 10px;
  text-align: start;
}

.sec8-bg {
  background-color: #943ecc;
  padding-bottom: 20px;
  padding-top: 1px;
  margin-top: 20px
}

.sec8-text-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sec8-text-main-div p {
  font-size: 30px;
  font-weight: 600;
}

.contact-us-form-div button {
  background-color: #ffff;
  color: #943ecc;
  padding: 5px 25px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
  border: none;
  margin-top: 25px;
}

.contact-text {
  color: white !important;
}

.footer-bg {
  background-color: #000000;
  padding: 50px 0;
}

.footer-logo-div {
  display: flex;
  align-items: center;
  gap: 20px 0;
}

.footer-text-div {
  text-align: left;
  color: white;
}

.footer-text-div ul {
  list-style-type: none;
}

.footer-list{
  cursor: pointer;
}

.copyRight{
  width: 100%;
  text-align: left;
  padding: 7px 0;
  color: rgb(255 255 255);
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  word-spacing: 5px;
}

.footer-social-icons{
  text-align: left;
}

.footer-social-icons a {
  color: white;
  text-decoration: none;
  text-align: left;
}

.footer-social-icons a:hover {
  color: #007bff; /* Change to your preferred hover color */
}

.footer-c-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-c-text p {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .home-main-text h1 {
    color: #304cd1;
    font-size: 36px;
    font-weight: 700 !important;
  }

  .copyRight{
    padding: 20px 0;
    font-size: 10px;
    word-spacing: 4px;
}
}