.creative-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .creative-list .list-item {
    display: flex;
    align-items: flex-start;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .creative-list .list-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .creative-list .list-icon {
    margin-right: 15px;
    font-size: 1.5em;
    color: #007bff;
  }
  
  .creative-list .list-content {
    flex: 1;
  }
  
  .creative-list .list-content b {
    display: block;
    margin-bottom: 5px;
    font-size: 1.1em;
  }
  .contact-text-p {
    text-align: left;
    color: white;
    font-size: 25px;
  }
  .contact-us-btn {
    background-color: #007bff;
    padding: 7px 35px;
    border: none;
  }
  .contact-us-btn:focus {
    background-color: transparent;
    border: none;
  }
  .contact-us-btn:hover {
    background-color: transparent;
    border: none;
  }
  .contact-us-form-div {
    color: white;
    text-align: left;
  }